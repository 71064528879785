import {ChangeDetectorRef, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {LocationDetail} from '@shared/models/area/location.model';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {SearchFilterComponent} from '@shared/components/search-filter/search-filter.component';
import {MatTableDataSource} from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {TranslationService} from '@shared/services/translation.service';
import {checkPageSize, DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS, pageChange} from '@shared/utils/utils';
import {merge} from 'rxjs';
import {filter, tap} from 'rxjs/operators';
import {LocationService} from '@shared/services/area/location.service';
import {AreaLocationSelectAddComponent} from '@shared/components/area-location-select-add/area-location-select-add.component';

export interface LocationDialogConfig {
  multiple: boolean;
  selected: LocationDetail;
  filter: boolean;
  sectionId: number;
}

@Component({
  selector: 'saf-area-location-select-dialog',
  templateUrl: './area-location-select-dialog.component.html',
  styleUrls: ['./area-location-select-dialog.component.scss']
})
export class AreaLocationSelectDialogComponent implements OnInit {
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(SearchFilterComponent) searchFilter: SearchFilterComponent;

  public tableColumns = ['select', 'name', 'department'];
  public dataSource = new MatTableDataSource();
  public loading = true;
  public totalCount = 0;
  public multiple = false;
  public drawerFilterParams: any = {};
  public searchFilterParams: any = {};
  public selection: SelectionModel<LocationDetail>;
  public selected = null;
  public filter = false;
  public sectionId = null;

  public trackById = (item) => item.id;

  constructor(
    public dialogRef: MatDialogRef<AreaLocationSelectDialogComponent, LocationDetail | LocationDetail[] | null>,
    private locationService: LocationService,
    private translationService: TranslationService,
    private dialog: MatDialog,
    private changeDetectorRef: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public config: LocationDialogConfig,
    @Inject(PAGE_SIZE_OPTIONS) public pageSizeOptions,
    @Inject(DEFAULT_PAGE_SIZE) public pageSize,
  ) {
    this.multiple = this.config.multiple;
    this.selected = this.config.selected;
    this.filter = this.config.filter;
    this.sectionId = this.config.sectionId;

    if (this.multiple) {
      this.selection = new SelectionModel<LocationDetail>(this.multiple, this.selected);
      if (this.selected) {
      }
    } else {
      this.selection = new SelectionModel<LocationDetail>(false, this.selected);
    }
  }

  ngOnInit(): void {
    LocationService.clearCache();
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page).subscribe(() => this.loadTable());
  }

  ngAfterViewInit() {
    this.loadTable();
    this.changeDetectorRef.detectChanges();
  }

  loadTable() {
    const params = {
      filter: {
        ...this.searchFilterParams,
        ...this.drawerFilterParams,
      },
      sort: this.sort.direction ? this.sort.active : '',
      dir: this.sort.direction,
      start: localStorage.getItem('start') ? localStorage.getItem('start') : this.paginator.pageIndex * this.paginator.pageSize,
      limit: this.paginator.pageSize,
    };

    if (this.sectionId) {
      params['filter']['sectionId'] = this.sectionId;
    }

    this.loading = true;
    localStorage.removeItem('start');

    this.locationService.getListDetail(params)
      .pipe(tap(() => this.loading = false))
      .subscribe((response) => {
        this.dataSource.data = response.data;
        this.totalCount = response.total;
      });
  }

  applyDrawerFilters(filterParams) {
    this.drawerFilterParams = filterParams;
    this.paginator.pageIndex = 0;
    this.loadTable();
  }

  closeDialog(e, locationDetail?: LocationDetail) {
    this.dialogRef.close(locationDetail);
  }

  select(item) {
    this.dialogRef.close(item);
  }

  cancel() {
    this.dialogRef.close(null);
  }

  submit() {
    this.dialogRef.close(this.selection.selected);
  }

  close(result = null) {
    this.dialogRef.close(result);
  }

  isAllSelected() {
    return this.selection.selected.length === this.dataSource.data.length;
  }

  isSelected(row: LocationDetail) {
    return !!(this.selection.selected.find(locationDetail => locationDetail.location.id === row.location.id));
  }

  toggleAll() {
    if (this.isAllSelected()) {
      this.selection.clear();
    } else {
      this.dataSource.data.forEach((row: LocationDetail) => this.selection.select(row));
    }
  }

  selectedRow(row: LocationDetail, e) {
    if (this.multiple) {
      if (e.checked) {
        this.selection.toggle(row);
      } else {
        const selection = this.selection.selected;
        this.selection.clear();
        selection.forEach(selected => {
          if (selected.location.id !== row.location.id) {
            this.selection.select(selected);
          }
        });
      }
    } else {
      if (e.checked) {
        this.selection.clear();
        this.selection.toggle(row);
      } else {
        this.selection.clear();
      }
    }
  }

  openDialog() {
    const dialogConfig: MatDialogConfig = {
      panelClass: 'small-dialog',
      disableClose: true,
      data: {
        sectionId: this.sectionId,
      },
    };

    this.dialog.open(AreaLocationSelectAddComponent, dialogConfig)
      .afterClosed()
      .pipe(filter((success) => success))
      .subscribe(result => {
        if (result.result) {
          this.locationService.getDetail(result.data.id)
            .subscribe((response) => {
              this.selection.clear();
              this.selection.select(response);
              this.dialogRef.close(this.selection.selected);
            });
        }
      });
  }

  reloadTable() {
    LocationService.clearCache();
    this.loadTable();
  }

  protected readonly pageChange = pageChange;
  protected readonly checkPageSize = checkPageSize;
}
