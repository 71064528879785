import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ChipFilterComponent} from '@shared/components/chip-filter/chip-filter.component';
import {SharedModule} from '@shared/shared.module';
import {MaterialModule} from '@shared/material.module';
import {FlexModule} from '@angular/flex-layout';
import {PipesModule} from "@shared/pipes/pipes.module";



@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        FlexModule,
        PipesModule,
    ],
  declarations: [
    ChipFilterComponent
  ],
  exports: [
    ChipFilterComponent
  ]
})
export class ChipFilterModule { }
