import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TunnelRestrictionSelectComponent } from './tunnel-restriction-select.component';
import {FormsModule} from "@angular/forms";
import {MaterialModule} from "@shared/material.module";
import {PipesModule} from "@shared/pipes/pipes.module";



@NgModule({
  declarations: [
    TunnelRestrictionSelectComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    PipesModule,
  ],
  exports: [
    TunnelRestrictionSelectComponent
  ]
})
export class TunnelRestrictionSelectModule { }
