import {
  AfterViewInit,
  Component, Inject,
  OnInit,
  ViewChild, ViewChildren,
} from '@angular/core';
import {DepartmentNode} from '@shared/models/department-node.model';
import {DepartmentService} from '@shared/services/department.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslationService} from '@shared/services/translation.service';
import {tap} from 'rxjs/operators';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TREE_ACTIONS, TreeComponent, TreeModel, TreeNode} from '@circlon/angular-tree-component';
import {PermissionService} from '@shared/services/permission.service';
import {Department} from '@shared/models/department.model';
import {MatSlideToggle} from '@angular/material/slide-toggle';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {PermissionKeys} from "@shared/models/permission.model";

export interface DepartmentDialogConfig {
  multiple: boolean;
  selected: any;
  valueText: any;
  isCompany: boolean;
}

@Component({
  selector: 'saf-department-select-dialog',
  templateUrl: './department-select-dialog.component.html',
  styleUrls: ['./department-select-dialog.component.scss']
})
export class DepartmentSelectDialogComponent implements OnInit, AfterViewInit {

  @ViewChild(TreeComponent) tree: TreeComponent;
  @ViewChildren(MatSlideToggle) toggleArray;
  public loading = false;
  public nodes: DepartmentNode[];
  public hasPermission: boolean;
  public checkedSection: Department[] = [];
  public isChecked: Number[] = [];
  private departmentDetail: Department;
  public formGroupArray: UntypedFormGroup;
  private formControls = {};
  legend: any = [
    { name: 'company', icon: 'location_city', css: 'companyIcon' },
    { name: 'cluster', icon: 'hub', css: 'clusterIcon' },
    { name: 'place', icon: 'room', css: 'placeIcon' },
    { name: 'department', icon: 'home', css: 'sectionIcon' },
    { name: 'workplace', icon: 'laptop', css: 'workstationIcon' }
  ];
  public options = {
    isExpandedField: 'true',
    displayField: 'text',
    actionMapping: {
      mouse: {
        click: (tree, node, $event) => {
          if (node.hasChildren) {
            TREE_ACTIONS.TOGGLE_EXPANDED(tree, node, $event);
          }
        }
      }
    }
  };

  constructor(
    public departmentService: DepartmentService,
    public dialogRef: MatDialogRef<DepartmentSelectDialogComponent, Department[] | null>,
    private translationService: TranslationService,
    private permissionService: PermissionService,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) private config: DepartmentDialogConfig,
  ) {
  }

  ngOnInit() {
    if (this.config.selected) {
      if (Array.isArray(this.config.selected)) {
        this.isChecked = this.config.selected;
      } else {
        this.isChecked.push(this.config.selected);
      }
      if (Array.isArray(this.config.selected)) {
        this.config.selected.forEach((section, index) => {
          this.checkedSection.push(new Department({id: section, name: this.config.valueText[index]}));
        });
      } else {
        this.checkedSection.push(new Department({id: this.config.selected, name: this.config.valueText.toString()}));
      }

    }
    this.hasPermission = this.permissionService.hasPermission(PermissionKeys.SAFETY_COMPANY_EDIT);
    this.loadNodes();
  }

  ngAfterViewInit() {
    if (Array.isArray(this.config.selected)) {
      this.config.selected.forEach(section => {
        this.loadById(section);
      });
    } else {
      if (this.config.selected) {
        this.loadById(this.config.selected);
      }
    }
  }

  loadNodes() {
    this.loading = true;
    const params =  {
      onlyCompany: this.config.isCompany
    };
    return this.departmentService.getList(params)
      .pipe(tap(() => this.loading = false))
      .subscribe((nodes: DepartmentNode[]) => {
        this.nodes = nodes;
        this.nodes.forEach(node => {
          // this.formControls[node.id] = new FormControl(true);
          this.formControls[node.id] = new UntypedFormControl(this.isChecked.includes(node.id));
          this.createControls(node.children);
        });
        this.formGroupArray = new UntypedFormGroup(this.formControls);
      });
  }

  loadById(sectionId: number) {
    this.departmentService.getById(sectionId)
      .pipe()
      .subscribe((result) => {
          result.parentElements.forEach(parent => {
            if (this.tree) {
              const someNode = this.tree.treeModel.getNodeById(parent.id);
              someNode.expand();
            }

          });
        }
      );
  }

  reloadNodes() {
    DepartmentService.clearCache();
    this.loadNodes();
  }

  applySearchFilter({param, value}) {
    this.tree.treeModel.filterNodes(value);
  }

  onMoveNode({node, to}) {
    this.departmentService.changePosition(node.id, to.parent.id, to.index);
  }

  saveSection(data: any, e, toggle) {
    e.stopPropagation();
    if (this.config.multiple) {
      if (!toggle) {
        this.checkedSection.push(data.data);
        data.data.children.forEach(child => {
          this.checkedSection.push(child);
          this.formGroupArray.controls[child.id].setValue(true);
          this.addChildren(child);
        });
        this.formGroupArray = new UntypedFormGroup(this.formControls);
      } else if (toggle) {
        this.checkedSection = this.checkedSection.filter(value => data.data.id !== value.id);
        this.uncheckChildren(data.data);
      }
    } else {
      if (!toggle) {
        this.checkedSection.forEach( (section, index) => {
          this.formGroupArray.controls[section.id].setValue(false);
          this.checkedSection.splice(index, 1);
        });
        this.checkedSection.push(data.data);
      } else if (toggle) {
        this.checkedSection.forEach( (section, index) => {
          if (section.id === data.data.id) {
            this.checkedSection.splice(index, 1);
          }
        });
      }
    }
  }

  addChildren(children: any) {
    children.children.forEach(child => {
      this.checkedSection.push(child);
      this.formGroupArray.controls[child.id].setValue(true);
      this.addChildren(child);
    });
  }

  uncheckChildren(children: any) {
    this.checkedSection = this.checkedSection.filter(value => children.id !== value.id);
    children.children.forEach(child => {
      this.checkedSection = this.checkedSection.filter(value => child.id !== value.id);
      this.formGroupArray.controls[child.id].setValue(false);
      this.uncheckChildren(child);
    });
  }

  createControls(children: any) {
    if (children.children) {
      children.children.forEach(child => {
        this.formControls[child.id] = new UntypedFormControl(this.isChecked.includes(child.id));
        this.createControls(child);
      });
    } else {
      children.forEach(child => {
        this.formControls[child.id] = new UntypedFormControl(this.isChecked.includes(child.id));
        this.createControls(child);
      });
    }
  }

  onUpdateData() {
    const treeModel: TreeModel = this.tree.treeModel;
    const firstTree: TreeNode = treeModel.getFirstRoot();
    return firstTree.expand();
  }

  submit() {
    this.dialogRef.close(this.checkedSection);
  }
}
