export enum ModuleColor {
  // NAVIGATION = 'rgba(0,96,100,1)',
  // NAVIGATION = 'linear-gradient(90deg, rgba(209, 222, 0, 1),rgba(188, 185, 164, 0.3))',
  NAVIGATION = 'rgba(209, 222, 0, 1)',
  // USER_ADMINISTRATION = 'rgba(0,96,100,1)',
  USER_ADMINISTRATION = 'rgba(209, 222, 0, 1)',
  // USER_ADMINISTRATION = 'rgba(33,163,110,0.95)',
  OBSERVATION = 'rgba(97,165,48,1)',
  AUDIT = 'rgba(33,112,196,1)',
  HAZARD_ASSESSMENT = 'rgba(212,185,15,1)',
  INSTRUCTION = 'rgba(226,110,18,1)',
  MEDICAL = '#1f6533',
  // MEDICAL = 'rgba(210,16,16,0.95)',
  EXTERNAL_COMPANIES = 'rgba(123,122,123,1)',
  MEASURE = 'rgba(193,62,156,1)',
  HAZARDOUS_SUBSTANCE = 'rgb(33,147,30)',
  CHECKLIST = 'rgba(150,59,184,1)',
  PERMISSION_SETTINGS = 'rgba(0,123,255,0.93)',
  NOTEPAD = 'rgba(86,160,255,1)',
  TODO = 'rgba(0,234,255,1)',
  SAFETY = 'rgb(27,96,100)',
  SCHOOLING = 'rgb(113,212,255)',
  QUALIFICATION = 'rgb(87,220,20)',
  SCREENING = 'rgb(58,120,150)',
  MAINTENANCEPLANNER = 'rgb(162,167,159)',
  CONTROLLING = 'rgb(129, 8, 194)',
}
