import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AreaRoomSelectComponent } from './area-room-select.component';
import {PipesModule} from "@shared/pipes/pipes.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MaterialModule} from "@shared/material.module";



@NgModule({
  declarations: [
    AreaRoomSelectComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,
    PipesModule,
  ],
  exports: [
    AreaRoomSelectComponent
  ]
})
export class AreaRoomSelectModule { }
