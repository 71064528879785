import {Department} from '@shared/models/department.model';

export class Location {
  id: number = null;
  sectionId: number = null;
  parentLocationId: number = null;
  name: string = '';
  addressId: number = null;

  constructor(config?: object) {
    if (config) {
      Object.keys(config)
        .filter(key => typeof config[key] !== 'undefined')
        .forEach(key => this[key] = config[key]);
    }
  }
}

export class LocationDetail {
  location: Location;
  parentLocation: Location = null;
  section: Department;
}
