import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {PipesModule} from "@shared/pipes/pipes.module";
import {FormsModule} from "@angular/forms";
import {MaterialModule} from "@shared/material.module";
import {
  HazardousNumberSelectComponent
} from "@shared/components/hazardous-number-select/hazardous-number-select.component";



@NgModule({
  declarations: [
    HazardousNumberSelectComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    PipesModule,
  ],
  exports: [
    HazardousNumberSelectComponent
  ]
})
export class HazardousNumberSelectModule { }
