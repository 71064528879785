import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TemperatureClassSelectComponent } from './temperature-class-select.component';
import {MatButtonModule} from "@angular/material/button";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatIconModule} from "@angular/material/icon";
import {MatOptionModule} from "@angular/material/core";
import {MatSelectModule} from "@angular/material/select";
import {NgxMatSelectSearchModule} from "ngx-mat-select-search";
import {PipesModule} from "@shared/pipes/pipes.module";
import {FormsModule} from "@angular/forms";
import {MaterialModule} from "@shared/material.module";



@NgModule({
  declarations: [
    TemperatureClassSelectComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    PipesModule,
  ],
  exports: [
    TemperatureClassSelectComponent
  ]
})
export class TemperatureClassSelectModule { }
