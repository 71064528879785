import {Department} from '@shared/models/department.model';
import {Employee} from '@shared/models/employee.model';

export class EquipmentCategory {
  id: number = null;
  sectionId: number = null;
  parentCategoryId: number = null;
  inspectorEmployeeId: number = null;
  name: string = '';
  description: string = '';

  constructor(config?: object) {
    if (config) {
      Object.keys(config)
        .filter(key => typeof config[key] !== 'undefined')
        .forEach(key => this[key] = config[key]);
    }
  }
}

export class EquipmentCategoryDetail {
  equipmentCategory: EquipmentCategory;
  section: Department;
  inspectorEmployee: Employee = null;
  parentCategory: EquipmentCategory = null;
  countChildCategories = 0;
}
