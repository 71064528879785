import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {tap} from "rxjs/operators";
import {DataResponse} from "@shared/models/response.model";
import {EquipmentCategory} from "@shared/models/maintenance/equipment-category.model";
import {EquipmentCategoryService} from "@shared/services/maintenance/equipment-category.service";

@Component({
  selector: 'saf-equipment-category-select-add',
  templateUrl: './equipment-category-select-add.component.html',
  styleUrls: ['./equipment-category-select-add.component.scss']
})
export class EquipmentCategorySelectAddComponent implements OnInit {
  public loading: boolean = false;
  public formGroup: UntypedFormGroup;
  public equipmentCategory: EquipmentCategory;
  public parentId: number = null;

  constructor(
    private dialogRef: MatDialogRef<EquipmentCategorySelectAddComponent>,
    private equipmentCategoryService: EquipmentCategoryService,
    @Inject(MAT_DIALOG_DATA) public config: any,
  ) { }

  ngOnInit(): void {
    this.parentId = this.config.parentId;
    this.equipmentCategory = new EquipmentCategory();
    this.buildForm(this.equipmentCategory);
  }

  buildForm(equipmentCategory: EquipmentCategory) {
    this.formGroup = new UntypedFormGroup({
      sectionId: new UntypedFormControl(equipmentCategory.sectionId, Validators.required),
      name: new UntypedFormControl(equipmentCategory.name, Validators.required),
      parentCategoryId: new UntypedFormControl(equipmentCategory.parentCategoryId ? equipmentCategory.parentCategoryId : this.parentId),
      description: new UntypedFormControl(equipmentCategory.description),
      inspectorEmployeeId: new UntypedFormControl(equipmentCategory.inspectorEmployeeId)
    });

    this.equipmentCategory = equipmentCategory;
  }

  submitForm() {
    if (this.parentId) {
      this.formGroup.get('sectionId').clearValidators();
      this.formGroup.get('sectionId').updateValueAndValidity();
    }
    if (this.formGroup.valid) {
      const request= this.equipmentCategoryService.create(this.formGroup.value);

      this.loading = true;

      request
        .pipe(tap(() => this.loading = false))
        .subscribe((result) => this.handleResult(result));
    }
  }

  handleResult(result: DataResponse<EquipmentCategory>) {
    if (result.success) {
      this.close(true, result.data);
    }
  }

  close(result: boolean = false, data: EquipmentCategory = null) {
    this.dialogRef.close({result, data});
  }
}
