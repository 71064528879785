import { NgModule } from '@angular/core';
import { MaterialModule } from '@shared/material.module';
import { CommonModule } from '@angular/common';
import { PipesModule } from '@shared/pipes/pipes.module';
import { EmployeeDialogComponent } from '@shared/components/employee-dialog/employee-dialog.component';
import { FilterPanelModule } from '@shared/components/filter-panel/filter-panel.module';
import { LoadingOverlayModule } from '@shared/components/loading-overlay/loading-overlay.module';
import { EmployeeCategoryFilterModule } from '@shared/components/employee-category-filter/employee-category-filter.module';
import { RoleFilterModule } from '@shared/components/role-filter/role-filter.module';
import { DepartmentFilterModule } from '@shared/components/department-filter/department-filter.module';
import { EmployeeActivityFilterModule } from '@shared/components/employee-activity-filter/employee-activity-filter.module';
import {ShowFromSubCategoryFilterModule} from '@shared/components/show-from-sub-category-filter/show-from-sub-category-filter.module';
import {ModuleFilterModule} from '@shared/components/module-filter/module-filter.module';
import {EmployeeEditingFilterModule} from '@shared/components/employee-editing-filter/employee-editing-filter.module';
import {QualificationFilterModule} from '@shared/components/qualification-filter/qualification-filter.module';
import {FilterDrawerModule} from '@shared/components/filter-drawer/filter-drawer.module';
import {FlexLayoutModule} from '@angular/flex-layout';
import {ProfileFilterModule} from '@shared/components/profile-filter/profile-filter.module';
import {EmptyMessageModule} from '@shared/components/empty-message/empty-message.module';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        PipesModule,
        FilterPanelModule,
        EmployeeCategoryFilterModule,
        RoleFilterModule,
        DepartmentFilterModule,
        EmployeeActivityFilterModule,
        LoadingOverlayModule,
        ShowFromSubCategoryFilterModule,
        ModuleFilterModule,
        EmployeeEditingFilterModule,
        QualificationFilterModule,
        FilterDrawerModule,
        FlexLayoutModule,
        ProfileFilterModule,
        EmptyMessageModule,
    ],
    declarations: [
        EmployeeDialogComponent,
    ],
    exports: [
        EmployeeDialogComponent,
    ]
})
export class EmployeeDialogModule {}
