import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HazardousSubstanceStatementSelectDialogComponent } from './hazardous-substance-statement-select-dialog.component';
import {MaterialModule} from "@shared/material.module";
import {FlexLayoutModule} from "@angular/flex-layout";
import {EmptyMessageModule} from "@shared/components/empty-message/empty-message.module";
import {LoadingSpinnerModule} from "@shared/components/loading-spinner/loading-spinner.module";
import {LoadingOverlayModule} from "@shared/components/loading-overlay/loading-overlay.module";
import {FilterPanelModule} from "@shared/components/filter-panel/filter-panel.module";
import {PipesModule} from "@shared/pipes/pipes.module";
import {ChipFilterModule} from "@shared/components/chip-filter/chip-filter.module";



@NgModule({
  declarations: [
    HazardousSubstanceStatementSelectDialogComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    EmptyMessageModule,
    LoadingSpinnerModule,
    LoadingOverlayModule,
    FilterPanelModule,
    PipesModule,
    ChipFilterModule
  ],
  exports: [
    HazardousSubstanceStatementSelectDialogComponent
  ]
})
export class HazardousSubstanceStatementSelectDialogModule { }
