import {
  AfterViewInit, ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DepartmentService } from '@shared/services/department.service';
import { EventEmitter } from '@angular/core';
import { DepartmentNode } from '@shared/models/department-node.model';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatFormField, MatFormFieldAppearance } from '@angular/material/form-field';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { of } from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections';
import { transformPanel } from '@shared/utils/animations';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { tap } from 'rxjs/operators';
import { TranslationService } from '@shared/services/translation.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {checkFilter, killEvent} from '@shared/utils/utils';
import {DepartmentSelectDialogComponent} from '@shared/components/department-select-dialog/department-select-dialog.component';
import {Department} from '@shared/models/department.model';

type DepartmentSelectionType = 'single' | 'with-children';
type DepartmentFilterFunction = (node: DepartmentNode) => boolean;

@Component({
  selector: 'saf-department-select',
  templateUrl: './department-select.component.html',
  styleUrls: ['./department-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DepartmentSelectComponent),
      multi: true
    }
  ],
  animations: [transformPanel],
})
export class DepartmentSelectComponent implements OnInit, AfterViewInit, ControlValueAccessor {

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) { this._required = coerceBooleanProperty(value); }
  private _required = false;

  @Input()
  get isCompany(): boolean { return this._isCompany; }
  set isCompany(value: boolean) { this._isCompany = coerceBooleanProperty(value); }
  private _isCompany = false;

  @Input()
  get isFilter(): boolean { return this._isFilter; }
  set isFilter(value: boolean) { this._isFilter = coerceBooleanProperty(value); }
  private _isFilter = false;

  @Input()
  get disabled(): boolean { return this._disabled || this._loading; }
  set disabled(value: boolean) { this._disabled = coerceBooleanProperty(value); }
  private _disabled = false;

  @Input()
  get multiple(): boolean { return this._multiple; }
  set multiple(value: boolean) { this._multiple = coerceBooleanProperty(value); }
  private _multiple = false;

  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) { this._placeholder = value; }
  private _placeholder: string;

  @Input()
  get appearance(): MatFormFieldAppearance { return this._appearance; }
  set appearance(value: MatFormFieldAppearance) { this._appearance = value; }
  private _appearance: MatFormFieldAppearance = 'outline';

  @Input()
  get value(): any { return this._value; }
  set value(value: any) { this.writeValue(value); }
  private _value: any = null;

  @Input()
  get singleCompany(): boolean { return this._singleCompany; }
  set singleCompany(value: boolean) { this._singleCompany = coerceBooleanProperty(value); }
  private _singleCompany = false;

  @Input()
  get departmentFilter(): DepartmentFilterFunction { return this._departmentFilter; }
  set departmentFilter(value: DepartmentFilterFunction) {
    this._departmentFilter = value;

    if (this._initialized && this._rawDepartments.length) {
      this.updateDepartments();
    }
  }
  private _departmentFilter: DepartmentFilterFunction = null;

  get loading(): boolean { return this._loading; }
  private _loading: boolean = false;

  get hasValue(): boolean {
    if (this.disabled === false) {
      return this.value !== null;
    } else {
      return false;
    }
  }

  get hasNoValue(): boolean {
    if (this._valueText === '' || this.value === null  || this.value === 0) {
      return true;
    }
  }

  _initialized: boolean = false;
  _rawDepartments: DepartmentNode[] = [];
  _departments: DepartmentNode[] = [];
  _dataSource: MatTreeNestedDataSource<DepartmentNode>;
  _treeControl: NestedTreeControl<DepartmentNode>;
  _treeSelection: SelectionModel<number>;
  _valueText: string = '';
  _searchText: string = '';
  _menuOpened: boolean = false;
  _menuOverlay: OverlayRef;

  @Input() valueField: string = 'id';
  @Input() labelField: string = 'text';
  @Input() listName = '';
  @Input() type: DepartmentSelectionType = 'single';
  @Output() valueChange = new EventEmitter<any>();
  @Output() valueChangeCompanyId = new EventEmitter<any>();
  @ViewChild('template') template: TemplateRef<any>;
  @ViewChild('filter') filter: ElementRef;
  @ViewChild('field') field: MatFormField;

  onChange: Function = () => {};
  onTouched: Function = () => {};

  constructor(
    private departmentService: DepartmentService,
    private viewContainer: ViewContainerRef,
    private overlay: Overlay,
    private changeDetectorRef: ChangeDetectorRef,
    private snackBar: MatSnackBar,
    private translationService: TranslationService,
    private dialog: MatDialog,
  ) {}

  ngOnInit() {
    this._dataSource = new MatTreeNestedDataSource();
    this._treeControl = new NestedTreeControl<DepartmentNode>((node) => of(node.children));
    this._treeSelection = new SelectionModel<number>(this.multiple);
    this._initialized = true;
    // this.checkSavedFilter();
  }

  checkSavedFilter() {
    if (!this._isCompany && this._isFilter && (checkFilter('sectionIds', this.listName))) {
      this.writeValue(checkFilter('sectionIds', this.listName));
    }

    if (this._isFilter && checkFilter('organiserCompanyId', this.listName)) {
      this.writeValue(checkFilter('organiserCompanyId', this.listName));
    }

    if (this._isCompany && this._isFilter && checkFilter('companyId', this.listName)) {
      this.writeValue(checkFilter('companyId', this.listName));
    }
  }

  checkSectionIsCompany(sections: any) {
    // console.log('checkSessionIsCompany()');
    sections.forEach(item => {
      if (this._isCompany && checkFilter('sectionIds', this.listName).some((section: any) => section === item.id)) {
        // console.log('setSections()');
        this.writeValue(item.id);
      }
      if (!this._isCompany && checkFilter('companyId', this.listName)) {
        // console.log('setCompanyId()');
        const ids = [];
        if (item.id === checkFilter('companyId', this.listName)) {
          ids.push(item.id);
          this.writeValue(ids);
        }
      }
    });
  }

  ngAfterViewInit() {
    this.checkSavedFilter();

    if (this.value) {
      if (this.value.length === 0) {
        this.value = null;

      }
      this.loadDepartments().subscribe((departments) => {
        this._rawDepartments = departments;
        this.updateDepartments();
        // this.checkSectionIsCompany(departments);

        if (this.value) {
          this.setInitialSelection(this.getNodesById(this.multiple ? this.value : [this.value]));
        }
        // if (this.value) {
        //   if (this.multiple) {
        //     this.value.forEach(section => {
        //       this.departmentService.getById(section)
        //         .pipe()
        //         .subscribe((result) => this.getDepartmentsTooltip(result));
        //     });
        //   } else {
        //     this.departmentService.getById(this.value)
        //       .pipe()
        //       .subscribe((result) => this.getDepartmentsTooltip(result));
        //   }
        // }
        this._treeSelection.changed.subscribe(() => this.onChangeSelection());
      });

      this.changeDetectorRef.detectChanges();
    }
  }

  updateDepartments() {
    this._departments = this.departmentFilter ? this.filterDepartments(this._rawDepartments) : this._rawDepartments;
    this._dataSource.data = this._departments;
    this._treeControl.dataNodes = this._departments;
  }

  filterDepartments(departments: DepartmentNode[], result = []) {
    return departments.reduce((acc, department) => {
      if (department.children.length) {
        this.filterDepartments(department.children, result);
      }

      if (this._departmentFilter(department)) {
        acc.push({ ...department, children: [] });
      }

      return acc;
    }, result);
  }

  registerOnChange(onChange: Function) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: Function) {
    this.onTouched = onTouched;
  }

  writeValue(value: any) {
    if (value !== this._value) {
      this._value = value;
      this.onChange(value);
      this.onTouched();
      this.valueChange.emit(value);
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  loadDepartments() {
    this._loading = true;

    return this.departmentService.getList()
      .pipe(tap(() => this._loading = false));
  }

  openMenu() {
    if (this.disabled || this.loading || this._menuOpened) {
      return;
    }

    const overlayConfig = new OverlayConfig({
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
      positionStrategy: this.overlay.position()
        .flexibleConnectedTo(this.field.getConnectedOverlayOrigin())
        .withPositions([{
          originX: 'start',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'top',
        }, {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'bottom',
        }]),
    });

    this._menuOverlay = this.overlay.create(overlayConfig);
    this._menuOverlay.attach(new TemplatePortal(this.template, this.viewContainer));
    this._menuOverlay.backdropClick().subscribe(() => this.closeMenu());
    this._menuOpened = true;
  }

  closeMenu() {
    if (!this._menuOpened) {
      return;
    }

    if (this.multiple && this.singleCompany) {
      const nodes = this.getSelectedNodes();

      if (nodes.length && nodes.some((node) => +node.cId !== +nodes[0].cId)) {
        this.snackBar.open(this.translationService.translate('selectDepartmentsFromOneCompany'));

        return;
      }
    }

    this._menuOpened = false;
    this._menuOverlay.detach();
    this._menuOverlay = null;
    this._treeControl.collapseAll();
    this.resetSearch();
  }

  focusFilter() {
    if (this.filter) {
      this.filter.nativeElement.focus();
    }
  }

  toggleAllNodes() {
    if (this._treeSelection.hasValue()) {
      this._treeSelection.clear();
    } else {
      this._treeSelection.select(...this._dataSource.data.reduce((acc, node) => {
        acc.push(...this._treeControl.getDescendants(node).map(descendant => descendant.id), node.id);

        return acc;
      }, []));
    }
  }

  getNodesById(ids) {
    const nodes = [];

    for (const id of ids) {
      for (const node of this._treeControl.dataNodes) {
        if (+node.id === +id) {
          nodes.push(node);

          break;
        }

        for (const descendant of this._treeControl.getDescendants(node)) {
          if (+descendant.id === +id) {
            nodes.push(descendant);

            break;
          }
        }
      }
    }

    return nodes;
  }

  getSelectedNodes() {
    return this.getNodesById(this._treeSelection.selected);
  }

  selectSingleNode(node) {
    this._treeSelection.select(node.id);
    this.closeMenu();
  }

  onChangeSelection() {
    if (!this._treeSelection.hasValue()) {
      this.writeValue(null);
      this._valueText = '';

      return;
    }

    const nodes = this.getSelectedNodes();

    if (this.multiple) {
      this.writeValue(nodes.map((node) => node[this.valueField]));
      this._valueText = nodes.map((node) => node[this.labelField]).join(', ');
    } else {
      this.writeValue(nodes[0][this.valueField]);
      this._valueText = nodes[0][this.labelField];
    }
  }

  onChangeSearchText() {
    if (!this._searchText) {
      this.resetSearch();

      return;
    }

    const filteredNodes = this.searchNodes(this._departments);

    this._dataSource.data = filteredNodes;
    this._treeControl.dataNodes = filteredNodes;
    this._treeControl.expandAll();
  }

  searchNodes(nodes) {
    return nodes.reduce((acc, node) => {
      const filteredNodes = this.searchNodes(node.children);

      if (filteredNodes.length > 0 || (node[this.labelField].toLowerCase().indexOf(this._searchText.toLowerCase()) !== -1)) {
        acc.push({ ...node, children: filteredNodes });
      }

      return acc;
    }, []);
  }

  resetSearch() {
    this._dataSource.data = this._departments;
    this._treeControl.dataNodes = this._departments;
    this._searchText = '';
  }

  clear() {
    this._valueText = '';
    this.value = null;
    this._treeSelection.clear();
  }

  onClickClear(event) {
    this.clear();
    event.stopImmediatePropagation();
  }

  onChangeCheckbox(node: DepartmentNode): void {
    if (this.type === 'single') {
      this._treeSelection.isSelected(node.id)
        ? this._treeSelection.deselect(node.id)
        : this._treeSelection.select(node.id);

      return;
    }

    const descendantIds = this._treeControl.getDescendants(node).map((descendant) => descendant.id);

    this._treeSelection.isSelected(node.id)
      ? this._treeSelection.deselect(node.id, ...descendantIds)
      : this._treeSelection.select(node.id, ...descendantIds);
  }

  allChildrenSelected(node: DepartmentNode): boolean {
    if (this.type === 'single') {
      return this._treeSelection.isSelected(node.id);
    }

    const descendantIds = this._treeControl.getDescendants(node).map((descendant) => descendant.id);

    if (!descendantIds.length) {
      return this._treeSelection.isSelected(node.id);
    }

    const allSelected = descendantIds.every(id => this._treeSelection.isSelected(id));

    if (!this._treeSelection.isSelected(node.id) && allSelected) {
      this._treeSelection.select(node.id);
    }

    return allSelected;
  }

  someChildrenSelected(node: DepartmentNode): boolean {
    if (this.type === 'single') {
      return false;
    }

    const descendants = this._treeControl.getDescendants(node);

    if (!descendants.length) {
      return false;
    }

    return descendants.some(child => this._treeSelection.isSelected(child.id)) && !this.allChildrenSelected(node);
  }

  private setInitialSelection(nodes: DepartmentNode[]) {
    this._treeSelection.select(...nodes.map((node) => node.id));

    if (this.multiple) {
      this._value = nodes.map((node) => node[this.valueField]);
      this._valueText = nodes.map((node) => node[this.labelField]).join(', ');
    } else {
      this._value = nodes[0][this.valueField];
      this._valueText = nodes[0][this.labelField];
    }
  }

  openDepartmentDialog(event) {
    if (this.disabled) { return; }
    const dialogConfig: MatDialogConfig = {
      disableClose: false,
      panelClass: 'table-dialog',
      data: {
        multiple: this.multiple,
        selected: this.value,
        isCompany: this._isCompany,
        valueText: this._valueText.includes(',') ? this._valueText.split(',') : [this._valueText]
      },
    };

    this.dialog.open(DepartmentSelectDialogComponent, dialogConfig)
      .afterClosed()
      .pipe()
      .subscribe((result) => {
        // const array = [];
        // result.forEach((filter: any) => {
        //   array.push({ id: filter.id, name: filter.name ? filter.name : filter.text });
        //   sessionStorage.setItem('filter', JSON.stringify(array));
        // });

        this.value = this.multiple
          ? result.map((section) => section.id)
          : result[0].id;
        this._valueText = this.multiple
          ? result.map((section) => section.name ? section.name : section.text).join(', ')
          : result[0].name ? result[0].name : result[0].text;
        this.valueChangeCompanyId.emit(result[0].cId);

        // maybe we need this later
        // if (!this.multiple) {
        //   this._loading = true;
        //   this.departmentService.getById(result[0].id)
        //     .pipe(tap(() => this._loading = false ))
        //     .subscribe((result) => this.getDepartmentsTooltip(result));
        // } else {
        //   this._valueText = result.map((section) => section.text + ' ');
        // }
      });

    killEvent(event);
  }

  getDepartmentPath(departments: any[]) {
    this._valueText =  departments.map((department) => department.name).join(' -> ');
    this._placeholder = this._valueText;
  }

  getDepartmentsTooltip(departments: Department) {
    this.getDepartmentPath(departments.parentElements);
  }
}
