import {
  Action,
  NgxsAfterBootstrap,
  Selector,
  State,
  StateContext, Store,
} from '@ngxs/store';
import {filter, tap} from 'rxjs/operators';
import { SetCurrentBreadcrumb, UpdateBreadcrumbs } from '@shared/store/breadcrumb/breadcrumb.actions';
import { Breadcrumb } from '@shared/models/breadcrumb.model';
import { ActivatedRoute, NavigationEnd, PRIMARY_OUTLET, Router } from '@angular/router';
import { TranslationService } from '@shared/services/translation.service';
import { TitleService } from '@shared/services/title.service';
import {Injectable} from '@angular/core';


export class BreadcrumbStateModel {
  currentBreadcrumb: string;
  breadcrumbs: Breadcrumb[];
}

@State<BreadcrumbStateModel>({
  name: 'breadcrumb',
  defaults: {
    currentBreadcrumb: '',
    breadcrumbs: [],
  }
})

@Injectable()
export class BreadcrumbState implements NgxsAfterBootstrap {

  @Selector()
  static currentBreadcrumb(state: BreadcrumbStateModel) {
    return state.currentBreadcrumb;
  }

  @Selector()
  static breadcrumbs(state: BreadcrumbStateModel) {
    return state.breadcrumbs;
  }

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translationService: TranslationService,
    private titleService: TitleService,
    private store: Store,
  ) {}

  ngxsAfterBootstrap({ dispatch, patchState }: StateContext<BreadcrumbStateModel>) {
    if (this.router.navigated) {
      dispatch(new UpdateBreadcrumbs());
    }

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => this.store.dispatch(new UpdateBreadcrumbs()));
  }

  @Action(SetCurrentBreadcrumb)
  setCurrentBreadcrumb({ dispatch, patchState }: StateContext<BreadcrumbStateModel>, { payload }: SetCurrentBreadcrumb) {
    patchState({ currentBreadcrumb: payload });
    this.titleService.setTitle(payload);
  }

  @Action([UpdateBreadcrumbs])
  updateBreadcrumbs({ patchState }: StateContext<BreadcrumbStateModel>) {
    const breadcrumbs = this.getBreadcrumbs(this.activatedRoute.root.children),
      currentBreadcrumb = breadcrumbs.length ? this.translationService.translate(breadcrumbs[breadcrumbs.length - 1].label) : '';

    patchState({
      breadcrumbs,
      currentBreadcrumb
    });

    this.titleService.setTitle(currentBreadcrumb);
  }

  getBreadcrumbs(routes: ActivatedRoute[], url: string = '', breadcrumbs: Breadcrumb[] = []): Breadcrumb[] {
    if (routes.length === 0) {
      return breadcrumbs;
    }

    for (let route of routes) {
      if (route.outlet !== PRIMARY_OUTLET) {
        continue;
      }

      if (!route.snapshot.data.title) {
        return this.getBreadcrumbs(route.children, url, breadcrumbs);
      }

      const breadcrumb = {
        label: route.snapshot.data.title,
        url: `${url}/${route.snapshot.url.map(segment => segment.path).join('/')}`,
      };

      breadcrumbs.push(breadcrumb);

      return this.getBreadcrumbs(route.children, breadcrumb.url, breadcrumbs);
    }
  }
}
