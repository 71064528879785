import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrintDialogComponent } from './print-dialog.component';
import {MaterialModule} from '@shared/material.module';
import {PipesModule} from '@shared/pipes/pipes.module';
import {LoadingOverlayModule} from '@shared/components/loading-overlay/loading-overlay.module';
import {FormsModule} from '@angular/forms';



@NgModule({
  declarations: [
    PrintDialogComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    PipesModule,
    LoadingOverlayModule,
    FormsModule,
  ],
  exports: [
    PrintDialogComponent
  ]
})
export class PrintDialogModule { }
