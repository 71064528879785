import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HazardousClassSelectComponent } from './hazardous-class-select.component';
import {PipesModule} from "@shared/pipes/pipes.module";
import {FormsModule} from "@angular/forms";
import {MaterialModule} from "@shared/material.module";



@NgModule({
  declarations: [
    HazardousClassSelectComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    PipesModule,
  ],
  exports: [
    HazardousClassSelectComponent
  ]
})
export class HazardousClassSelectModule { }
