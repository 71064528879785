import {AfterViewInit, Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {MatFormFieldAppearance} from '@angular/material/form-field';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {filter, tap} from 'rxjs/operators';
import {EquipmentCategory} from '@shared/models/maintenance/equipment-category.model';
import {EquipmentCategoryService} from '@shared/services/maintenance/equipment-category.service';
import {
  EquipmentCategorySelectDialogComponent
} from '@shared/components/equipment-category-select-dialog/equipment-category-select-dialog.component';
import {PermissionService} from "@shared/services/permission.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslationService} from "@shared/services/translation.service";
import {
  EquipmentCategorySelectAddComponent
} from "@shared/components/equipment-category-select-add/equipment-category-select-add.component";
import {PermissionKeys} from "@shared/models/permission.model";
import {checkFilter} from '@shared/utils/utils';

@Component({
  selector: 'saf-equipment-category-select',
  templateUrl: './equipment-category-select.component.html',
  styleUrls: ['./equipment-category-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EquipmentCategorySelectComponent),
      multi: true
    }
  ],
})
export class EquipmentCategorySelectComponent implements OnInit, ControlValueAccessor, AfterViewInit {
  _valueText: string = '';
  private equipmentCategory: EquipmentCategory;
  private hasPermission: boolean = false;
  @Input() parentId: number = 0;

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) { this._required = coerceBooleanProperty(value); }
  private _required = false;

  @Input()
  get disabled(): boolean { return this._disabled || this._loading; }
  set disabled(value: boolean) { this._disabled = coerceBooleanProperty(value); }
  public _disabled = false;

  @Input()
  get multiple(): boolean { return this._multiple; }
  set multiple(value: boolean) { this._multiple = coerceBooleanProperty(value); }
  private _multiple = false;

  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) { this._placeholder = value; }
  private _placeholder: string;

  @Input()
  get appearance(): MatFormFieldAppearance { return this._appearance; }
  set appearance(value: MatFormFieldAppearance) { this._appearance = value; }
  private _appearance: MatFormFieldAppearance = 'outline';

  @Input()
  get value(): any { return this._value; }
  set value(value: any) { this.writeValue(value); }
  private _value: any = null;

  @Input()
  get isFilter(): boolean { return this._isFilter; }
  set isFilter(value: boolean) { this._isFilter = coerceBooleanProperty(value); }
  private _isFilter = false;

  get loading(): boolean { return this._loading; }
  private _loading: boolean = false;

  get hasValue(): boolean {
    if (this.disabled === false) {
      return !!this._valueText;
    } else {
      return false;
    }
  }

  get hasNoValue(): boolean {
    if (this._valueText === '' || this.disabled === true) {
      return true;
    }
  }

  @Output() valueChange = new EventEmitter<any>();
  @Output() valueChangeCategory = new EventEmitter<any>();
  @Input() listName = '';

  onChange: Function = () => {};
  onTouched: Function = () => {};

  constructor(
    private equipmentCategoryService: EquipmentCategoryService,
    private dialog: MatDialog,
    private translationService: TranslationService,
    private permissionService: PermissionService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.hasPermission = this.permissionService.hasPermission(PermissionKeys.MAINTENANCE_EDIT);
    this.checkSavedFilter();
  }

  checkSavedFilter() {
    if (checkFilter('equipmentCategoryId', this.listName)) {
      this.writeValue(checkFilter('equipmentCategoryId', this.listName));
    }
  }

  ngAfterViewInit() {
    this.loadById(this.value);
  }

  registerOnChange(onChange: Function) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: Function) {
    this.onTouched = onTouched;
  }

  writeValue(value: any) {
    this._value = value;
    this.onChange(value);
    this.onTouched();
    this.valueChange.emit(value);
    if (value === null) {
      this._valueText = '';
      this.equipmentCategory = null;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  loadById(id?: number) {
    if (id) {
      this._loading = true;

      this.equipmentCategoryService.getById(id)
        .pipe(tap(() => this._loading = false))
        .subscribe((response) => {
          this.valueChangeCategory.emit(response);
          this._valueText = response.name;
          this.equipmentCategory = response;
        });
    }
  }

  openEquipmentCategorySelectDialog(e) {
    if (this.disabled) { return; }

    const dialogConfig: MatDialogConfig = {
      panelClass: 'table-dialog',
      disableClose: false,
      data: {
        multiple: this.multiple,
        selected: this.equipmentCategory ? this.equipmentCategory instanceof Array ? this.equipmentCategory : [this.equipmentCategory] : null,
        filter: this.isFilter,
        parentId: this.parentId
      }
    };

    this._loading = true;
    this.dialog.open(EquipmentCategorySelectDialogComponent, dialogConfig)
      .afterClosed()
      .pipe(tap(() => this._loading = false))
      .subscribe((result) => {
        if (!result) {
          return;
        }
        if (result.length === 0) {
          this.value = null;
          this._valueText = '';
          this.equipmentCategory = null;
        } else {
          this.valueChangeCategory.emit(result[0]);
          this.value = this.multiple
            ? result.map(equipmentCategory => equipmentCategory.id)
            : result.map(equipmentCategory => equipmentCategory.id)[0];
          this._valueText = this.multiple
            ? result.map((equipmentCategory) => equipmentCategory.name).join(', ')
            : result.map(equipmentCategory => equipmentCategory.name)[0];
          this.equipmentCategory = result;
        }
      });
  }

  clear() {
    this.value = null;
    this._valueText = '';
    this.equipmentCategory = null;
  }

  onClickClear(event) {
    this.clear();
    event.stopImmediatePropagation();
  }

  returnPermissionError() {
    return this.snackBar.open(this.translationService.translate('permissionError'));
  }
}
