import {NgModule} from '@angular/core';
import {TranslatePipe} from '@shared/pipes/translate.pipe';
import {HasPermissionPipe} from '@shared/pipes/has-permission.pipe';
import {CommonModule} from '@angular/common';
import {MatNativeDateModule} from '@angular/material/core';
import {SafeHtmlPipe} from '@shared/pipes/safe-html.pipe';
import {FromNowPipe} from '@shared/pipes/from-now.pipe';
import {DatePipe} from '@shared/pipes/date.pipe';
import {DateTimePipe} from '@shared/pipes/date-time.pipe';
import {SortPipe} from '@shared/pipes/sort.pipe';
import {RoleIconPipe} from '@shared/pipes/role-icon.pipe';
import {ModuleIconPipe} from '@shared/pipes/module-icon';
import {DecodeURIPipe} from '@shared/pipes/decodeURI.pipe';
import {ProgressBarPipe} from './progress-bar.pipe';
import {TimePipe} from '@shared/pipes/time.pipe';

@NgModule({
  imports: [
    CommonModule,
    MatNativeDateModule,
  ],
  declarations: [
    TranslatePipe,
    SafeHtmlPipe,
    DatePipe,
    DateTimePipe,
    FromNowPipe,
    SortPipe,
    RoleIconPipe,
    ModuleIconPipe,
    HasPermissionPipe,
    DecodeURIPipe,
    ProgressBarPipe,
    TimePipe
  ],
  exports: [
    TranslatePipe,
    SafeHtmlPipe,
    DatePipe,
    DateTimePipe,
    FromNowPipe,
    SortPipe,
    RoleIconPipe,
    ModuleIconPipe,
    HasPermissionPipe,
    DecodeURIPipe,
    ProgressBarPipe,
    TimePipe
  ],
})
export class PipesModule {
}
