<mat-dialog-content>
  <div class="container" fxLayout="column" fxLayoutGap="16px" fxLayoutGap.xs="8px">
    <div class="stickyHeader closeCross">
      <span mat-dialog-title>{{ ('corporateStructure') | translate }}</span>
      <mat-icon [matDialogClose]="true" class="cross">close</mat-icon>
      <mat-card class="department-content">
        <section fxLayout="row" fxLayoutGap="16px" class="table-header">
          <saf-search-filter fxFlex="1 0 auto" (valueChange)="applySearchFilter($event)"></saf-search-filter>
        </section>
        <section class="iconContainer">
          <div *ngFor="let item of legend; let last = last" style="display: flex; align-items: center">
            <mat-icon class="companyIcon {{ item.css }}" style="margin-right: 10px" fontSet="material-symbols-outlined">
              {{ item.icon }}
            </mat-icon>
            <span>{{ item.name | translate }}</span>
            <div *ngIf="!last" class="borderRight"></div>
          </div>
        </section>
      </mat-card>
    </div>
    <mat-card class="department-content">

    <div class="department-children dialog-table-container" style="margin-top: 10px" [fxShow]="nodes">
      <form *ngIf="formGroupArray" [formGroup]="formGroupArray">
      <tree-root  *ngIf="nodes" [nodes]="nodes"  [options]="options">
        <ng-template  #treeNodeTemplate let-node let-index="index">
          <div class="department-container parentTree">
            <mat-slide-toggle [formControlName]="node.data.id" #toggleStat class="sliderToggle" (click)="saveSection(node, $event, toggleStat.checked)"></mat-slide-toggle>
            <ng-container [ngSwitch]="node.data.type">
              <mat-icon [matTooltip]="('company' | translate)" *ngSwitchCase="'company'" class="type-icon companyIcon"
                        fontSet="material-symbols-outlined">location_city</mat-icon>
              <mat-icon [matTooltip]="('cluster' | translate)" *ngSwitchCase="'cluster'" class="type-icon clusterIcon"
                        fontSet="material-symbols-outlined">hub</mat-icon>
              <mat-icon [matTooltip]="('place' | translate)" *ngSwitchCase="'place'" class="type-icon placeIcon"
                        fontSet="material-symbols-outlined">room</mat-icon>
              <ng-container *ngIf="node.data.workplace">
                <mat-icon [matTooltip]="('workplace' | translate)" *ngSwitchCase="'section'" class="type-icon workstationIcon"
                          fontSet="material-symbols-outlined">laptop</mat-icon>
              </ng-container>
              <ng-container *ngIf="!node.data.workplace">
                <mat-icon [matTooltip]="('department' | translate)" *ngSwitchCase="'section'" class="type-icon sectionIcon"
                          fontSet="material-symbols-outlined">home</mat-icon>
              </ng-container>
            </ng-container>
            <span class="department-name">{{ node.data.text }}</span>
            <span class="spacer"></span>
          </div>
        </ng-template>
        <ng-template #loadingTemplate>{{ 'loading' | translate }}</ng-template>
      </tree-root>
      </form>
    </div>
    <saf-empty-message *ngIf="!nodes && !loading" (reloadClicked)="reloadNodes()"></saf-empty-message>
  </mat-card>
  </div>
</mat-dialog-content>

<div mat-dialog-actions align="end">
  <button class="administration deleteButtonHover" [matDialogClose]="true" mat-raised-button color="primary" >
    <span>{{ 'cancel' | translate }}</span>
  </button>
  <button class="administration saveButtonHover" (click)="submit()" mat-raised-button color="primary" >
    <span>{{ 'save' | translate }}</span>
  </button>
</div>
